import {
  ModalProvider,
  ToastContextProvider,
  TooltipProvider,
} from '@leland-dev/leland-ui-library';
import { type AppProps } from 'next/app';
import React, { useEffect } from 'react';
import Modal from 'react-modal';

import AuthContextProvider from '../context/AuthContext';
import FeatureInteractionsProvider from '../context/FeatureInteractionsContext';
import LexContextProvider from '../context/LexContext';
import OnboardingStatusesContextProvider from '../context/OnboardingStatusesContext';
import SavingIndicatorContextProvider from '../context/SavingIndicatorContext';
import { ApolloProviderWithAuth0 } from '../lib/ApolloProviderWithAuth0';
import Auth0ProviderWrapper from '../lib/Auth0ProviderWrapper';
import { useGtm } from '../utils/gtm';
import { init as initNProgress } from '../utils/nprogress';
import { init as initRollbar } from '../utils/rollbar';

/* eslint-disable import/order */
import 'nprogress/nprogress.css'; //styles of nprogress
import '@sendbird/uikit-react/dist/index.css'; // sendbird styles
import '../styles/app.css';
import { useRouter } from 'next/router';
import { OpportunityProvider } from '../context/OpportunityContext';
/* eslint-enable import/order */

initRollbar();

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    // @ts-expect-error `lelandVersion` does not exist on `window`
    window.lelandVersion = process.env.NEXT_PUBLIC_LELAND_VERSION;
  }, []);

  useEffect(() => {
    initNProgress();
  }, []);

  // Initialize/use GTM
  useGtm();

  return (
    <div
      ref={(el) => {
        if (el) {
          // Bind modal to app element (https://reactcommunity.org/react-modal/accessibility/)
          Modal.setAppElement(el);
        }
      }}
    >
      <Auth0ProviderWrapper>
        <ApolloProviderWithAuth0 pageProps={pageProps as unknown}>
          <AuthContextProvider>
            <LexContextProvider>
              <FeatureInteractionsProvider>
                <OnboardingStatusesContextProvider>
                  <ToastContextProvider>
                    <TooltipProvider>
                      <SavingIndicatorContextProvider>
                        <ModalProvider router={router}>
                          <OpportunityProvider>
                            <Component {...pageProps} />
                          </OpportunityProvider>
                        </ModalProvider>
                      </SavingIndicatorContextProvider>
                    </TooltipProvider>
                  </ToastContextProvider>
                </OnboardingStatusesContextProvider>
              </FeatureInteractionsProvider>
            </LexContextProvider>
          </AuthContextProvider>
        </ApolloProviderWithAuth0>
      </Auth0ProviderWrapper>
    </div>
  );
};

export default MyApp;
