// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OneOnOneSessionModalCoachingSessionFragment = {
  __typename?: 'CoachingSession';
  id: string;
  startAt: number;
  endAt: number;
  status: Types.CoachingSessionStatus;
  attendees?: Array<{ __typename?: 'Applicant'; id: string }> | null;
  content:
    | { __typename?: 'Class' }
    | {
        __typename?: 'OneOnOneSession';
        title: string;
        order?: {
          __typename?: 'Order';
          id: string;
          introCall?: { __typename: 'IntroCall' } | null;
        } | null;
      };
};

export type OneOnOneSessionModalCoachFragment = {
  __typename?: 'Coach';
  id: string;
};

export type OneOnOneSessionModalCoachingTimeSummaryQueryVariables =
  Types.Exact<{
    applicantUrn: Types.Scalars['ApplicantUrn']['input'];
  }>;

export type OneOnOneSessionModalCoachingTimeSummaryQuery = {
  __typename?: 'Query';
  applicant?: {
    __typename?: 'Applicant';
    id: string;
    coachingTimeSummary?: {
      __typename?: 'CoachingTimeSummary';
      availableTime: number;
      hasUnlimitedPackage: boolean;
    } | null;
  } | null;
};

export type OneOnOneSessionModalCreateCoachingSessionMutationVariables =
  Types.Exact<{
    input: Types.CoachingSessionInput;
  }>;

export type OneOnOneSessionModalCreateCoachingSessionMutation = {
  __typename?: 'Mutation';
  createCoachingSession: {
    __typename?: 'CoachingSession';
    id: string;
    startAt: number;
    endAt: number;
    status: Types.CoachingSessionStatus;
    attendees?: Array<{ __typename?: 'Applicant'; id: string }> | null;
    content:
      | { __typename?: 'Class' }
      | {
          __typename?: 'OneOnOneSession';
          title: string;
          order?: {
            __typename?: 'Order';
            id: string;
            introCall?: { __typename: 'IntroCall' } | null;
          } | null;
        };
  };
};

export type OneOnOneSessionModalUpdateCoachingSessionMutationVariables =
  Types.Exact<{
    id: Types.Scalars['CoachingSessionUrn']['input'];
    input: Types.CoachingSessionPatchInput;
  }>;

export type OneOnOneSessionModalUpdateCoachingSessionMutation = {
  __typename?: 'Mutation';
  updateCoachingSession: {
    __typename?: 'CoachingSession';
    id: string;
    startAt: number;
    endAt: number;
    status: Types.CoachingSessionStatus;
    attendees?: Array<{ __typename?: 'Applicant'; id: string }> | null;
    content:
      | { __typename?: 'Class' }
      | {
          __typename?: 'OneOnOneSession';
          title: string;
          order?: {
            __typename?: 'Order';
            id: string;
            introCall?: { __typename: 'IntroCall' } | null;
          } | null;
        };
  };
};

export const OneOnOneSessionModalCoachingSessionFragmentDoc = gql`
  fragment OneOnOneSessionModal_CoachingSession on CoachingSession {
    id
    startAt
    endAt
    status
    attendees(count: 1) {
      id
    }
    content {
      ... on OneOnOneSession {
        title
        order {
          id
          introCall {
            __typename
          }
        }
      }
    }
  }
`;
export const OneOnOneSessionModalCoachFragmentDoc = gql`
  fragment OneOnOneSessionModal_Coach on Coach {
    id
  }
`;
export const OneOnOneSessionModalCoachingTimeSummaryDocument = gql`
  query OneOnOneSessionModal_CoachingTimeSummary($applicantUrn: ApplicantUrn!) {
    applicant(id: $applicantUrn) {
      id
      coachingTimeSummary {
        availableTime
        hasUnlimitedPackage
      }
    }
  }
`;

/**
 * __useOneOnOneSessionModalCoachingTimeSummaryQuery__
 *
 * To run a query within a React component, call `useOneOnOneSessionModalCoachingTimeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneOnOneSessionModalCoachingTimeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneOnOneSessionModalCoachingTimeSummaryQuery({
 *   variables: {
 *      applicantUrn: // value for 'applicantUrn'
 *   },
 * });
 */
export function useOneOnOneSessionModalCoachingTimeSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    OneOnOneSessionModalCoachingTimeSummaryQuery,
    OneOnOneSessionModalCoachingTimeSummaryQueryVariables
  > &
    (
      | {
          variables: OneOnOneSessionModalCoachingTimeSummaryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OneOnOneSessionModalCoachingTimeSummaryQuery,
    OneOnOneSessionModalCoachingTimeSummaryQueryVariables
  >(OneOnOneSessionModalCoachingTimeSummaryDocument, options);
}
export function useOneOnOneSessionModalCoachingTimeSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OneOnOneSessionModalCoachingTimeSummaryQuery,
    OneOnOneSessionModalCoachingTimeSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OneOnOneSessionModalCoachingTimeSummaryQuery,
    OneOnOneSessionModalCoachingTimeSummaryQueryVariables
  >(OneOnOneSessionModalCoachingTimeSummaryDocument, options);
}
export function useOneOnOneSessionModalCoachingTimeSummarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OneOnOneSessionModalCoachingTimeSummaryQuery,
    OneOnOneSessionModalCoachingTimeSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OneOnOneSessionModalCoachingTimeSummaryQuery,
    OneOnOneSessionModalCoachingTimeSummaryQueryVariables
  >(OneOnOneSessionModalCoachingTimeSummaryDocument, options);
}
export type OneOnOneSessionModalCoachingTimeSummaryQueryHookResult = ReturnType<
  typeof useOneOnOneSessionModalCoachingTimeSummaryQuery
>;
export type OneOnOneSessionModalCoachingTimeSummaryLazyQueryHookResult =
  ReturnType<typeof useOneOnOneSessionModalCoachingTimeSummaryLazyQuery>;
export type OneOnOneSessionModalCoachingTimeSummarySuspenseQueryHookResult =
  ReturnType<typeof useOneOnOneSessionModalCoachingTimeSummarySuspenseQuery>;
export type OneOnOneSessionModalCoachingTimeSummaryQueryResult =
  Apollo.QueryResult<
    OneOnOneSessionModalCoachingTimeSummaryQuery,
    OneOnOneSessionModalCoachingTimeSummaryQueryVariables
  >;
export const OneOnOneSessionModalCreateCoachingSessionDocument = gql`
  mutation OneOnOneSessionModal_CreateCoachingSession(
    $input: CoachingSessionInput!
  ) {
    createCoachingSession(input: $input) {
      ...OneOnOneSessionModal_CoachingSession
    }
  }
  ${OneOnOneSessionModalCoachingSessionFragmentDoc}
`;
export type OneOnOneSessionModalCreateCoachingSessionMutationFn =
  Apollo.MutationFunction<
    OneOnOneSessionModalCreateCoachingSessionMutation,
    OneOnOneSessionModalCreateCoachingSessionMutationVariables
  >;

/**
 * __useOneOnOneSessionModalCreateCoachingSessionMutation__
 *
 * To run a mutation, you first call `useOneOnOneSessionModalCreateCoachingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOneOnOneSessionModalCreateCoachingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oneOnOneSessionModalCreateCoachingSessionMutation, { data, loading, error }] = useOneOnOneSessionModalCreateCoachingSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOneOnOneSessionModalCreateCoachingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OneOnOneSessionModalCreateCoachingSessionMutation,
    OneOnOneSessionModalCreateCoachingSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OneOnOneSessionModalCreateCoachingSessionMutation,
    OneOnOneSessionModalCreateCoachingSessionMutationVariables
  >(OneOnOneSessionModalCreateCoachingSessionDocument, options);
}
export type OneOnOneSessionModalCreateCoachingSessionMutationHookResult =
  ReturnType<typeof useOneOnOneSessionModalCreateCoachingSessionMutation>;
export type OneOnOneSessionModalCreateCoachingSessionMutationResult =
  Apollo.MutationResult<OneOnOneSessionModalCreateCoachingSessionMutation>;
export type OneOnOneSessionModalCreateCoachingSessionMutationOptions =
  Apollo.BaseMutationOptions<
    OneOnOneSessionModalCreateCoachingSessionMutation,
    OneOnOneSessionModalCreateCoachingSessionMutationVariables
  >;
export const OneOnOneSessionModalUpdateCoachingSessionDocument = gql`
  mutation OneOnOneSessionModal_UpdateCoachingSession(
    $id: CoachingSessionUrn!
    $input: CoachingSessionPatchInput!
  ) {
    updateCoachingSession(id: $id, input: $input) {
      ...OneOnOneSessionModal_CoachingSession
    }
  }
  ${OneOnOneSessionModalCoachingSessionFragmentDoc}
`;
export type OneOnOneSessionModalUpdateCoachingSessionMutationFn =
  Apollo.MutationFunction<
    OneOnOneSessionModalUpdateCoachingSessionMutation,
    OneOnOneSessionModalUpdateCoachingSessionMutationVariables
  >;

/**
 * __useOneOnOneSessionModalUpdateCoachingSessionMutation__
 *
 * To run a mutation, you first call `useOneOnOneSessionModalUpdateCoachingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOneOnOneSessionModalUpdateCoachingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oneOnOneSessionModalUpdateCoachingSessionMutation, { data, loading, error }] = useOneOnOneSessionModalUpdateCoachingSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOneOnOneSessionModalUpdateCoachingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OneOnOneSessionModalUpdateCoachingSessionMutation,
    OneOnOneSessionModalUpdateCoachingSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OneOnOneSessionModalUpdateCoachingSessionMutation,
    OneOnOneSessionModalUpdateCoachingSessionMutationVariables
  >(OneOnOneSessionModalUpdateCoachingSessionDocument, options);
}
export type OneOnOneSessionModalUpdateCoachingSessionMutationHookResult =
  ReturnType<typeof useOneOnOneSessionModalUpdateCoachingSessionMutation>;
export type OneOnOneSessionModalUpdateCoachingSessionMutationResult =
  Apollo.MutationResult<OneOnOneSessionModalUpdateCoachingSessionMutation>;
export type OneOnOneSessionModalUpdateCoachingSessionMutationOptions =
  Apollo.BaseMutationOptions<
    OneOnOneSessionModalUpdateCoachingSessionMutation,
    OneOnOneSessionModalUpdateCoachingSessionMutationVariables
  >;
