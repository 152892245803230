// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnboardingStatusesContextUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type OnboardingStatusesContextUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    coach?: {
      __typename?: 'Coach';
      id: string;
      status: Types.CoachStatus;
      liveAt?: number | null;
      onboardingSessionAttended?: boolean | null;
      stripeActivated?: boolean | null;
      calendarLink?: string | null;
      hourlyRate?: number | null;
      geoLocation?: { __typename?: 'GeoLocation'; address: string } | null;
      educationExperiences?: Array<{ __typename: 'Education' }> | null;
      introVideo?: { __typename?: 'VideoLink'; link: string } | null;
      categoryInformationList?: Array<{
        __typename?: 'CategoryInformation';
        id: string;
        isCategoryComplete?: boolean | null;
        category: { __typename?: 'Category'; id: string };
      }> | null;
      hourlyTerm?: {
        __typename?: 'PackageTerm';
        refundPolicy: Types.RefundPolicy;
      } | null;
      availability?: {
        __typename?: 'CoachAvailability';
        defaultAvailability?: {
          __typename?: 'DefaultAvailability';
          totalInMinute: number;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const OnboardingStatusesContextUserDocument = gql`
  query OnboardingStatusesContext_User {
    user {
      id
      firstName
      lastName
      pictureLink
      coach {
        id
        status
        liveAt
        geoLocation {
          address
        }
        educationExperiences {
          __typename
        }
        introVideo {
          link
        }
        categoryInformationList {
          id
          category {
            id
          }
          isCategoryComplete
        }
        onboardingSessionAttended
        stripeActivated
        calendarLink
        hourlyRate
        hourlyTerm {
          refundPolicy
        }
        availability {
          defaultAvailability {
            totalInMinute
          }
        }
      }
    }
  }
`;

/**
 * __useOnboardingStatusesContextUserQuery__
 *
 * To run a query within a React component, call `useOnboardingStatusesContextUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingStatusesContextUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingStatusesContextUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingStatusesContextUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OnboardingStatusesContextUserQuery,
    OnboardingStatusesContextUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OnboardingStatusesContextUserQuery,
    OnboardingStatusesContextUserQueryVariables
  >(OnboardingStatusesContextUserDocument, options);
}
export function useOnboardingStatusesContextUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingStatusesContextUserQuery,
    OnboardingStatusesContextUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OnboardingStatusesContextUserQuery,
    OnboardingStatusesContextUserQueryVariables
  >(OnboardingStatusesContextUserDocument, options);
}
export function useOnboardingStatusesContextUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OnboardingStatusesContextUserQuery,
    OnboardingStatusesContextUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OnboardingStatusesContextUserQuery,
    OnboardingStatusesContextUserQueryVariables
  >(OnboardingStatusesContextUserDocument, options);
}
export type OnboardingStatusesContextUserQueryHookResult = ReturnType<
  typeof useOnboardingStatusesContextUserQuery
>;
export type OnboardingStatusesContextUserLazyQueryHookResult = ReturnType<
  typeof useOnboardingStatusesContextUserLazyQuery
>;
export type OnboardingStatusesContextUserSuspenseQueryHookResult = ReturnType<
  typeof useOnboardingStatusesContextUserSuspenseQuery
>;
export type OnboardingStatusesContextUserQueryResult = Apollo.QueryResult<
  OnboardingStatusesContextUserQuery,
  OnboardingStatusesContextUserQueryVariables
>;
